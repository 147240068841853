import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/8r_da4aD9Jo">
    <SEO title="Why Do We Start Churches? - Why Church?" />
  </Layout>
)

export default SermonPost
